import React from "react";
import { Heading } from "../components/Heading.jsx";
import iconWeb from "./../assets/icon-web-dev.png";
import iconCloudInfra from "./../assets/icon-cloud-infra.png";
import iconQuality from "./../assets/icon-quality.png";
import iconUx from "./../assets/icon-ux.png";
import iconPm from "./../assets/icon-pm.png";
import iconConsulting from "./../assets/icon-consulting.png";
import { Section } from "../components/Section.jsx";
import { useTranslation } from 'react-i18next';

export const Services = () => {
    const { t } = useTranslation();

    return (
        <Section className="items-center">
            <Heading level={2} className="max-w-lg text-center">
                {t('services.heading')}
            </Heading>
            <p className="text-2xl text-center max-w-xl">
                {t('services.intro')}
            </p>

            <div className="relative">
                <ul className="flex flex-wrap">
                    <ServiceItem
                        title={t('services.appDevelopment.title')}
                        description={t('services.appDevelopment.description')}
                        icon={iconWeb}
                    />
                    <ServiceItem
                        title={t('services.ux.title')}
                        description={t('services.ux.description')}
                        icon={iconUx}
                    />
                    <ServiceItem
                        title={t('services.transparency.title')}
                        description={t('services.transparency.description')}
                        icon={iconPm}
                    />
                    <ServiceItem
                        title={t('services.devOps.title')}
                        description={t('services.devOps.description')}
                        icon={iconCloudInfra}
                    />
                    <ServiceItem
                        title={t('services.quality.title')}
                        description={t('services.quality.description')}
                        icon={iconQuality}
                    />
                    <ServiceItem
                        title={t('services.consulting.title')}
                        description={t('services.consulting.description')}
                        icon={iconConsulting}
                    />
                </ul>
            </div>
        </Section>
    );
};

const ServiceItem = ({ title, description, icon }) => {
    return (
        <li className="basis-full md:basis-1/2 lg:basis-1/3 py-4 md:p-4">
            <div className="flex gap-4 bg-slate-900 p-4 h-full rounded md:flex-col md:py-6 md:px-8">
                {icon && <img src={icon} className="h-12 md:h-20 self-start" alt={`icon ${title}`} />}
                <div className="flex flex-col gap-2">
                    {title && <Heading level={3}>{title}</Heading>}
                    {description && <p className="text-lg max-w-lg">{description}</p>}
                </div>
            </div>
        </li>
    );
};
