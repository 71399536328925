import React, { useState } from "react";
import { Emphasis } from "../components/Emphasis.jsx";
import { Heading } from "../components/Heading.jsx";
import pandaCoffee from "./../assets/panda-coffee.png";
import pandaHeart from "./../assets/panda-heart.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { Section } from "../components/Section.jsx";
import { Form } from "../components/form/Form.jsx";
import { Button } from "../components/Button.jsx";
import { Trans, useTranslation } from "react-i18next";

export const Contact = () => {
    const { t } = useTranslation();

    const contactFormFields = {
        "fullName": {
            translationKey: "contact.form.fullName",
            isRequired: true,
            inputType: "text",
            flexBasis: "1/2",
            value: ""
        },
        "company": {
            translationKey: "contact.form.company",
            isRequired: false,
            inputType: "text",
            flexBasis: "1/2",
            value: ""
        },
        "email": {
            translationKey: "contact.form.email",
            isRequired: true,
            inputType: "email",
            value: ""
        },
        "details": {
            translationKey: "contact.form.details",
            isRequired: true,
            inputType: "textarea",
            value: "",
        },
    };

    const [formStatus, setFormStatus] = useState("initial"); // "initial", "success", "fail"
    const [isLoading, setIsLoading] = useState(false);


    const submitContactForm = async (formFields) => {
        try {
            console.log("submitting form...")
            setIsLoading(true);
            const { fullName, company, email, details } = formFields;

            const contactApiUrl = "https://faas-fra1-afec6ce7.doserverless.co/api/v1/web/fn-b527aed2-bb0d-49b2-bd4c-a727da2478d7/purplepanda/contact_panda";
            const apiUrl = `${contactApiUrl}?full_name=${encodeURIComponent(fullName.value)}&company=${encodeURIComponent(company.value)}&message=${encodeURIComponent(details.value)}&company_email=${encodeURIComponent(email.value)}`;

            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'x-require-whisk-auth': 'purplepandacorp',
                    //'webhook_only': 'true', // uncomment when debugging
                },
            });

            console.log(response);
            if (response.ok) {
                setFormStatus("success");
                console.log("Form submitted successfully");
            } else {
                if (response.status === 503) {
                    setFormStatus("fail");
                    console.error("Service Unavailable (503)");
                } else if (response.status === 400) {
                    setFormStatus("initial");
                    console.error("Bad Request (400)");
                } else {
                    setFormStatus("fail");
                    console.error(`Unexpected response: ${response.status}`);
                }
            }
        } catch (error) {
            setFormStatus("fail");
            console.error("Error submitting form:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const resetFormStatus = () => {
        setFormStatus("initial");
    };

    return (
        <Section id="contact-section" className="items-center">
            <Heading level={2} className="text-center">
                <Trans i18nKey="contact.heading.base">
                    Interested? <Emphasis>{{ contactUs: t('contact.heading.contactUs') }}</Emphasis>
                </Trans>
            </Heading>

            <div className="self-stretch flex flex-col gap-4 items-center lg:gap-16 xl:items-start xl:flex-row xl:justify-center ">
                <div className="flex flex-col items-center gap-4 lg:gap-12 xl:items-stretch xl:basis-1/2 max-w-lg">
                    <img src={pandaCoffee} className="hidden md:block w-40 lg:w-64" alt="a purple panda drinking coffee" />
                    <p className="text-2xl text-center xl:text-left">{t('contact.intro')}</p>

                    <ul className="flex flex-col gap-8 py-4 text-textMain ">
                        <li className="text-2xl lg:text-3xl flex items-baseline gap-6">
                            <FontAwesomeIcon icon={faEnvelope} className="text-primary text-xl" />
                            <a className="underline hover:text-primary transition-colors" href="mailto:contact@purplepanda.at">contact@purplepanda.at</a>
                        </li>
                    </ul>
                </div>

                {formStatus === "success" ?
                    <ContactSuccessMessage resetFormStatus={resetFormStatus} /> :
                    <div className="xl:basis-1/2 xl:order-2 flex flex-col gap-4 max-w-lg">
                        <Form
                            fields={contactFormFields}
                            submitText={t('contact.form.submit')}
                            onSubmit={submitContactForm}
                            isLoading={isLoading}
                        />
                        {formStatus === "fail" && (
                            <p className="text-lg px-4 py-2 max-w-lg rounded border-2 border-rose-600 bg-rose-900 text-white">
                                <Trans i18nKey="contact.form.error" link="contact@purplepanda.at">
                                    Something went wrong trying to send out your message. Please contact us directly at <a className="font-bold underline" href="mailto:contact@purplepanda.at">{{ link: "contact@purplepanda.at" }}</a>
                                </Trans>
                            </p>
                        )}
                    </div>
                }


            </div>
        </Section>
    )
};

const ContactSuccessMessage = ({ resetFormStatus }) => {
    const { t } = useTranslation();

    return (
    <div className="flex flex-col items-center gap-8 xl:basis-1/2 xl:order-2 bg-slate-900 p-16 rounded-lg max-w-lg">
        <img src={pandaHeart} className="w-24 lg:w-32" alt="a purple panda holding a heart shaped pillow" />
            <Heading level={3} styleAs={4} className="text-center">{t('contact.form.success.title')}</Heading>

            <p className="text-xl text-center max-w-md">{t('contact.form.success.description')}</p>
            <Button onClick={resetFormStatus}>{t('contact.form.success.button')}</Button>
        </div>)
} 