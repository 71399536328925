// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/librebodoni-variablefont_wght-webfont.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/librebodoni-variablefont_wght-webfont.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/poppins-regular-webfont.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./fonts/poppins-regular-webfont.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./fonts/poppins-bold-webfont.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./fonts/poppins-bold-webfont.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./fonts/poppins-extrabold-webfont.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("./fonts/poppins-extrabold-webfont.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #080C16;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: 'LibreBodoni';
  font-style: 'normal';
  font-weight: 400;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff'), 
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff2');
}
@font-face {
  font-family: 'Poppins';
  font-style: 'normal';
  font-weight: 400;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff'), 
    url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff2');
}
@font-face {
  font-family: 'Poppins';
  font-style: 'normal';
  font-weight: 700;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('woff'), 
    url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('woff2');
}
@font-face {
  font-family: 'Poppins';
  font-style: 'normal';
  font-weight: 800;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format('woff'), 
    url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format('woff2');
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":";AACA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;EAClC,yBAAyB;AAC3B;;AAEA;EACE;aACW;AACb;;;AAGA;EACE,0BAA0B;EAC1B,oBAAoB;EACpB,gBAAgB;EAChB;2DAC0E;AAC5E;AACA;EACE,sBAAsB;EACtB,oBAAoB;EACpB,gBAAgB;EAChB;2DAC4D;AAC9D;AACA;EACE,sBAAsB;EACtB,oBAAoB;EACpB,gBAAgB;EAChB;2DACyD;AAC3D;AACA;EACE,sBAAsB;EACtB,oBAAoB;EACpB,gBAAgB;EAChB;2DAC8D;AAChE","sourcesContent":["\nbody {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background-color: #080C16;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n\n@font-face {\n  font-family: 'LibreBodoni';\n  font-style: 'normal';\n  font-weight: 400;\n  src: url(./fonts/librebodoni-variablefont_wght-webfont.woff) format('woff'), \n    url(./fonts/librebodoni-variablefont_wght-webfont.woff2) format('woff2');\n}\n@font-face {\n  font-family: 'Poppins';\n  font-style: 'normal';\n  font-weight: 400;\n  src: url(./fonts/poppins-regular-webfont.woff) format('woff'), \n    url(./fonts/poppins-regular-webfont.woff2) format('woff2');\n}\n@font-face {\n  font-family: 'Poppins';\n  font-style: 'normal';\n  font-weight: 700;\n  src: url(./fonts/poppins-bold-webfont.woff) format('woff'), \n    url(./fonts/poppins-bold-webfont.woff2) format('woff2');\n}\n@font-face {\n  font-family: 'Poppins';\n  font-style: 'normal';\n  font-weight: 800;\n  src: url(./fonts/poppins-extrabold-webfont.woff) format('woff'), \n    url(./fonts/poppins-extrabold-webfont.woff2) format('woff2');\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
