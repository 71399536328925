import React from "react";
import { Emphasis } from "../components/Emphasis.jsx";
import { Heading } from "../components/Heading.jsx";
import { Section } from "../components/Section.jsx";
import { Trans, useTranslation } from "react-i18next";

export const Process = () => {
    const { t } = useTranslation();

    return (
        <Section className="items-center">
            <Heading level={2} className="max-w-lg text-center">
                <Trans i18nKey="process.heading.base">
                    Collaborate in our <Emphasis>{{ streamlined: t('process.heading.streamlined') }}</Emphasis> process
                </Trans>
            </Heading>
            <div className="relative">
                <SquigglyLine isHorizontal={true} className="hidden 3xl:block" />
                <SquigglyLine className="3xl:hidden" />
                <ul className="flex flex-col gap-16 3xl:flex-row">
                    <ProcessStep
                        title={t('process.discover.title')}
                        description={t('process.discover.description')}
                    />
                    <ProcessStep
                        title={t('process.develop.title')}
                        description={t('process.develop.description')}
                    />
                    <ProcessStep
                        title={t('process.support.title')}
                        description={t('process.support.description')}
                    />
                </ul>
            </div>
        </Section>
    );
};


const ProcessStep = ({ title, description }) => {
    return (
        <li className="basis-1/2 flex flex-col gap-4 pl-16 relative 
            before:content-[''] before:block before:absolute before:w-8 before:h-8 before:left-0 before:z-10
            before:rounded-full before:border-4 before:border-primary before:bg-violet-950 
            3xl:before:relative 3xl:pl-0
            last:after:content-[''] last:after:block last:after:absolute last:after:w-8 last:after:top-8 last:after:left-0 last:after:bottom-0 last:after:z-0
            last:after:bg-background 3xl:last:after:h-8 3xl:last:after:w-full 3xl:last:after:bottom-auto  3xl:last:after:top-0
            ">
            <Heading level={3}>{title}</Heading>
            <p className="text-lg max-w-lg">{description}</p>
        </li>
    );
};
const SquigglyLine = ({ isHorizontal, className }) => {
    const linePatternHorizontal = `radial-gradient(30.73px at 50% calc(100% + 22.00px),#0000 calc(99% - 2px),currentColor calc(101% - 2px) 99%,#0000 101%) calc(50% - 40px) calc(50% - 11px + .5px)/80px 22px ,
    radial-gradient(30.73px at 50% -22px,#0000 calc(99% - 2px),currentColor calc(101% - 2px) 99%,#0000 101%) 50% calc(50% + 11px)/80px 22px`;
    const linePatternVertical = `radial-gradient(30.73px at calc(100% + 22.00px) 50%,#0000 calc(99% - 2px),currentColor calc(101% - 2px) 99%,#0000 101%) calc(50% - 11px + .5px) calc(50% - 40px)/ 22px 80px  ,
    radial-gradient(30.73px at -22px 50%,#0000 calc(99% - 2px),currentColor calc(101% - 2px) 99%,#0000 101%) calc(50% + 11px) 50%/22px 80px`;
    return (
        <span
            className={`block text-5xl text-primary absolute ${isHorizontal ? "h-6 left-2 right-2 top-1" : "bottom-0 w-6 left-1 top-2"} ${className ?? ""}`}
            style={{ background: isHorizontal ? linePatternHorizontal : linePatternVertical }}>
        </span>
    );
};