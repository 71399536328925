import React from "react";
import { motion } from "framer-motion"

export const Emphasis = ({ order = 0, children }) => {
    return (
        <motion.em
            className="transition-all inline-block h-[1em] relative not-italic"
        >
            <motion.span
                className="absolute bg-primary inset-0 z-[-1] scale-[1.02]"
                initial={{ right: '100%' }}
                whileInView={{ right: 0 }}
                transition={{ delay: (order * 0.5) + 0.5, duration: 0.5 }}
                viewport={{ once: true }}
            ></motion.span>
            {children}
        </motion.em>
    );
};
