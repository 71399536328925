import React from "react";


export const Section = ({ id, className, children }) => {
    return (
        <section
            {...(id ? { id: id } : {})}
            className={`flex flex-col px-8 md:px-16 lg:px-32 py-16 md:py-24 lg:py-32 gap-4 sm:gap-6 md:gap-8 lg:gap-12 xl:gap-16 ${className ?? ""}`}>
            {children}
        </section>
    );
};
