import { createElement } from "react";
export const Heading = (props) => {
    const { level = 1, styleAs, children, ...rest } = props;

    const styleLevel = styleAs ?? level;
    const fontSize = styleLevel === 1 ? "text-4xl md:text-5xl" : styleLevel === 2 ? "text-4xl" : styleLevel === 3 ? "text-3xl" : styleLevel === 4 ? "text-xl" : "text-xl";
    const fontStyles = styleLevel === 3 ? "font-serif" : "font-extrabold uppercase tracking-wide";
    const styleClasses = `${fontSize} ${fontStyles} text-textMain  leading-tight`;
    return createElement(`h${level}`, { ...rest, className: `${styleClasses} ${rest?.className ?? ""}` }, children);
};
