import React from "react";

export const buttonStyles = `flex items-baseline gap-3 p-3 md:px-6 text-lg md:text-xl text-textMain font-bold uppercase leading-none tracking-wider
    rounded bg-gradient-to-br from-primary via-secondary to-rose-500 bg-[length:200%_200%] bg-top hover:bg-bottom transition-all duration-700
    shadow-lg shadow-primary/30 hover:shadow-xl hover:shadow-secondary/30 cursor-pointer
    disabled:bg-slate-400 disabled:bg-none disabled:shadow-none disabled:cursor-default`;

// eslint-disable-next-line no-script-url
export const Button = ({ asAnchor = false, onClick, href = "javascript:void(0);", className, children }) => {
    return (
        asAnchor ?
            (<a
                href={href}
                className={`${buttonStyles} ${className ?? ""}`}
            >
                {children}
            </a>) :
            (<button
                className={`${buttonStyles} ${className ?? ""}`}
                onClick={onClick}
            >
                {children}
            </button>)
    );
};
