import logo from "./../assets/logo-purple-panda-corp-white.png";
import React from "react";
import { Button } from "./Button.jsx";
import { useTranslation, } from 'react-i18next';
import { languages } from "../i18n.js";

export const NavBar = () => {
	const { t } = useTranslation();
	return (
		<header className="flex items-center justify-between px-8 lg:px-32 py-4 mt-12 sticky top-0 bg-background z-50">
			<img src={logo} className="w-32 sm:w-44 md:w-64 lg:w-80 xl:w-92" alt={t('general.imgAlt.logo')} />
			<div className="flex items-center gap-4">

				<LanguageSwitcher />
				<p className="hidden md:block text-lg">{t('navbar.wantToTalk')}</p>
				<Button asAnchor href="#contact-section">{t('navbar.button')}</Button>

			</div>
		</header>
	);
};

const LanguageSwitcher = () => {
	const { i18n } = useTranslation();

	return (
		<ul className="flex gap-2 border-r border-slate-700 pr-4">
			{languages.map((lang) => {
				const isCurrentLang = i18n.language.includes(lang);
				return isCurrentLang ?
					<li className="uppercase font-bold text-textMain" key={`language-${lang}`}>
						{lang}
					</li>
					: <li key={`language-${lang}`}>
						<button
							className="uppercase font-bold transition-colors hover:text-textMain"
							type="submit"
							onClick={() => i18n.changeLanguage(lang)}>
							{lang}
						</button>
					</li>
			})}
		</ul>
	);

}