import React from "react";
import { Button } from "../components/Button.jsx";
import { Emphasis } from "../components/Emphasis.jsx";
import { Heading } from "../components/Heading.jsx";
import panda from "./../assets/panda-laptop.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Section } from "../components/Section.jsx";
import { Trans, useTranslation } from 'react-i18next';

export const Hero = () => {
    const { t } = useTranslation();
    return (
        <Section className="xl:flex-row xl:items-center xl:justify-between relative">

            <div className="flex flex-col gap-8 items-start xl:basis-2/3 xl:order-first">
                <Heading>
                    <Trans i18nKey="hero.heading.base">
                        From <Emphasis>{{ vision: t('hero.heading.vision') }}</Emphasis> to <Emphasis order={1}>{{ production: t('hero.heading.production') }}</Emphasis><br /> Your Ideas, Our Execution.
                    </Trans>
                </Heading>
                <p className="text-xl max-w-lg">
                    {t('hero.intro1')}
                </p>
                <p className="text-xl max-w-lg">
                    {t('hero.intro2')}
                </p>
                <Button asAnchor href="#contact-section">
                    {t('hero.button')}
                    <FontAwesomeIcon icon={faArrowRight} className="text-base" />
                </Button>
            </div >
            <div className="hidden sm:block absolute right-16 bottom-0 w-32 md:w-48 self-end right-0  xl:order-last xl:w-auto xl:basis-1/3 xl:static">
                <img src={panda} className="w-100" alt={t('general.imgAlt.pandaLaptop')} />
            </div>
        </Section>
    );
};
