import React from "react";
import logo from "./../assets/logo-purple-panda-corp-white.png";

export const Footer = () => {
    return (
        <footer className="flex flex-col sm:flex-row items-center justify-center gap-4 md:gap-16 p-6 md:p-8 lg:p-12 bg-black">
            <img src={logo} className="h-8" alt="logo" />
            <p>2024 • PurplePanda Corp.</p>
        </footer>
    );
};
