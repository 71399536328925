import "./App.css";
import React from "react";

import { NavBar } from "./components/NavBar.jsx";
import { Hero } from "./sections/Hero.jsx";
import { Process } from "./sections/Process.jsx";
import { Services } from "./sections/Services.jsx";
import { Contact } from "./sections/Contact.jsx";
import { Footer } from "./sections/Footer.jsx";
import { Tools } from "./sections/Tools.jsx";

function App() {
	return (
		<div className="App font-sans text-base text-textLight leading-normal">
			<NavBar />
			<Hero />
			<Process />
			<Services />
			<Tools />
			<Contact />
			<Footer />
		</div>
	);
}

export default App;
