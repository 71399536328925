import { useTranslation } from "react-i18next";
import { inputStyles } from "./Input.jsx";

export const TextArea = ({ id, required = false, placeholder, translationKey, onBlur, onChange, className }) => {


    const { t, i18n } = useTranslation();

    // if there is a translation key available, get the placeholder from the translation file
    const translatedPlaceholder = translationKey && i18n.exists(`${translationKey}.placeholder`) ? t(`${translationKey}.placeholder`) : placeholder;

    return (
        <textarea
            className={`${inputStyles} ${className ?? ""}`}
            name={id}
            id={id}
            placeholder={translatedPlaceholder}
            {...(required ? { required: true } : {})}
            rows="7"
            onBlur={onBlur}
            onChange={onChange}
        />
    );
};