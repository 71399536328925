import React from "react";
import { Heading } from "../components/Heading.jsx";
import logoGo from "./../assets/golang.png";
import logoFlutter from "./../assets/icon-flutter.png";
import logoMaterial from "./../assets/icon-material.png";
import logoFigma from "./../assets/icon-figma.png";
import logoPython from "./../assets/icon-python.png";
import logoKafka from "./../assets/icon-kafka.png";
import logoKube from "./../assets/kubernetes.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { Section } from "../components/Section.jsx";
import { Trans, useTranslation } from 'react-i18next';
import { Emphasis } from "../components/Emphasis.jsx";

export const Tools = () => {
    const { t } = useTranslation();

    return (
        <Section className="items-center">

            <Heading level={2} className="max-w-lg text-center">
                <Trans i18nKey="tools.heading.base">Our <Emphasis>{{ tools: t('tools.heading.tools') }}</Emphasis> of choice</Trans>
            </Heading>
            <p className="text-2xl text-center max-w-xl">
                {t('tools.intro')}
            </p>

            <ul className="flex flex-wrap self-stretch">
                <TechnologyItem
                    title="Python"
                    description={t('tools.python')}
                    icon={logoPython}
                    link="https://www.python.org/"
                />
                <TechnologyItem
                    title="KUBERNETES"
                    description={t('tools.kubernetes')}
                    icon={logoKube}
                    link="https://kubernetes.io/"
                />
                <TechnologyItem
                    title={<><span className="font-normal text-textLight">Apache </span>Kafka</>}
                    description={t('tools.kafka')}
                    icon={logoKafka}
                    link="https://kafka.apache.org/"
                />
                <TechnologyItem
                    title="Flutter"
                    description={t('tools.flutter')}
                    icon={logoFlutter}
                    link="https://flutter.dev/"
                />
                <TechnologyItem
                    title="Material Motion"
                    description={t('tools.framer')}
                    icon={logoMaterial}
                    link="https://m3.material.io/styles/motion/overview"
                />
                <TechnologyItem
                    title="Figma"
                    description={t('tools.figma')}
                    icon={logoFigma}
                    link="https://www.figma.com/"
                />
            </ul>
        </Section>
    );
};

const TechnologyItem = ({ icon, title, description, link }) => {

    const wrapperClassNames = "flex items-center gap-6 bg-slate-900 p-6 h-full rounded relative"

    return (
        <li className="basis-full md:basis-1/2 lg:basis-1/3 py-4 md:p-4">
            {link ?
                (<a
                    className={`${wrapperClassNames} group hover:scale-105 transition-all hover:shadow-lg hover:shadow-violet-600/30
                    after:block after:absolute after:content-[''] after:h-1 after:transition-all after:duration-500
                    after:bg-gradient-to-r after:from-primary after:to-secondary after:left-0 after:right-full after:bottom-0
                    hover:after:opacity-100 hover:after:right-0`}
                    // eslint-disable-next-line no-script-url
                    href={link ?? "javascript:void(0);"}
                    {...(link ? { target: "_blank" } : {})}
                >
                    <img src={icon} className="h-12" alt={`logo ${title}`} />
                    <span className="flex flex-col">
                        <Heading level={3} styleAs={4}>{title}</Heading>
                        <p>{description}</p>
                        <span className="absolute flex items-center p-4 inset-y-0 right-0 group-hover:opacity-100 opacity-0 text-textLight text-xl">
                            <FontAwesomeIcon icon={faChevronRight} />
                        </span>
                    </span>
                </a>) :
                <div className={wrapperClassNames}>
                    <img src={icon} className="h-12" alt={`logo ${title}`} />
                    <span className="flex flex-col">
                        <Heading level={3} styleAs={4}>{title}</Heading>
                        <p>{description}</p>
                    </span>
                </div>
            }
        </li>
    );
};
