import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from "react-i18next";

export const FormGroup = ({ id, label, description, translationKey, required, errorKey, className, children }) => {

    const { t, i18n } = useTranslation();

    // if there is a translation key available, get the label and description from the translation file
    const translatedLabel = translationKey && i18n.exists(`${translationKey}.label`) ? t(`${translationKey}.label`) : label;
    const translatedDescription = translationKey && i18n.exists(`${translationKey}.description`) ? t(`${translationKey}.description`) : description;

    return (
        <div className={`flex flex-col gap-2 px-2 ${className ?? ""}`}>
            <label className="font-bold text-lg" htmlFor={id}>
                {translatedLabel}
                {required && <span className="text-primary">*</span>}
            </label>
            {children}
            {errorKey && <p className="flex gap-2 items-baseline rounded bg-rose-950 border-2 border-rose-400 text-white self-start px-2 py-1">
                <FontAwesomeIcon icon={faCircleExclamation} className="text-rose-400 text-sm" />
                {t(errorKey)}
            </p>}
            {translatedDescription && <p>{translatedDescription}</p>}
        </div>
    );
};