import { buttonStyles } from "../Button.jsx";

export const Submit = ({ text, disabled, isLoading, onSubmit, className }) => {
    return (
        <div className={`relative mx-2 rounded overflow-hidden ${className ?? ""}`}>
            <input
                className={`${buttonStyles}`}
                type="submit"
                value={text}
                {...(disabled ? { disabled: true } : {})}
                onSubmit={onSubmit}
            />
            {isLoading &&
                <span className="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-br from-primary via-secondary to-rose-500 animate-loaderBar"></span>}
        </div>
    );
};