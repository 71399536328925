import { useTranslation } from "react-i18next";

export const inputStyles = "px-4 py-2 rounded text-slate-900 focus:ring focus:ring-primary";

export const Input = ({ id, inputType, placeholder, translationKey, onBlur, onChange, className, value }) => {

    const { t, i18n } = useTranslation();

    // if there is a translation key available, get the placeholder from the translation file
    const translatedPlaceholder = translationKey && i18n.exists(`${translationKey}.placeholder`) ? t(`${translationKey}.placeholder`) : placeholder;

    return (
        <input
            className={`${inputStyles} ${className ?? ""}`}
            type={`${inputType}`}
            name={id}
            id={id}
            value={value}
            placeholder={translatedPlaceholder}
            onBlur={onBlur}
            onChange={onChange}
        />
    );
};