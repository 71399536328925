import React, { useMemo, useState } from "react";
import { FormGroup } from "./FormGroup.jsx";
import { Input } from "./Input.jsx";
import { Submit } from "./Submit.jsx";
import { TextArea } from "./TextArea.jsx";
import * as EmailValidator from 'email-validator';

export const Form = ({ fields, submitText = "Confirm", className, isLoading, onSubmit }) => {

    const [formFields, setFormFields] = useState(fields);
    const isFormValid = useMemo(() => Object.values(formFields).every(field => !field.errorKey), [formFields]);

    const validateField = (id) => {
        return () => {
            if (formFields[id].isRequired && formFields[id].value === "") {
                updateErrorKey(id, 'general.errors.isRequired')
            } else if (formFields[id].inputType === "email" && !EmailValidator.validate(formFields[id].value)) {
                updateErrorKey(id, 'general.errors.email')
            }
        }
    }
    const updateErrorKey = (id, errorKey) => {
        setFormFields({ ...formFields, [id]: { ...formFields[id], errorKey } })
    }
    const updateValue = (id, value) => {
        setFormFields({ ...formFields, [id]: { ...formFields[id], value, errorKey: null } })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        Object.keys(formFields).forEach((id) => validateField(id)());
        if (isFormValid && onSubmit && typeof onSubmit === 'function') {
            onSubmit(formFields);
        }
    }
    return (
        <form
            className={`flex flex-col gap-6 max-w-lg ${className ?? ""}`}
            onSubmit={handleSubmit}
        >
            <div
                className={`flex flex-row flex-wrap items-stretch gap-y-6 ${isLoading ? "opacity-50" : ""} `}
            >
            {
                Object.keys(formFields).map((id) => {
                    const { translationKey, label, isRequired, errorKey, inputType, placeholder, description, value, flexBasis = "full" } = formFields[id];

                    return (
                        <FormGroup
                            key={id}
                            id={id}
                            label={label}
                            description={description}
                            translationKey={translationKey}
                            {...(isRequired ? { required: true } : {})}
                            errorKey={errorKey}
                            className={`grow basis-${flexBasis}`}
                        >
                        {(inputType === "text" || inputType === "email" || inputType === "password") &&
                            <Input
                                id={id}
                                placeholder={placeholder}
                                translationKey={translationKey}
                                {...(isRequired ? { required: true } : {})}
                                value={value}
                                onChange={(e) => updateValue(id, e.target.value)}
                                onBlur={validateField(id)}
                                inputType={inputType}
                            />}
                        {(inputType === "textarea") &&
                            <TextArea
                                id={id}
                                placeholder={placeholder}
                                translationKey={translationKey}
                                {...(isRequired ? { required: true } : {})}
                                value={value}
                                onChange={(e) => updateValue(id, e.target.value)}
                                onBlur={validateField(id)}
                            />}
                        </FormGroup>)
                })
            }
            </div>
            <Submit
                isLoading={isLoading}
                text={submitText}
                className={"self-center"}
                disabled={!isFormValid || isLoading}
            />
        </form>
    );
};